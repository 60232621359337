import { CwtApiSchema, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { EvaluationResultEntity } from "./eval-result.entity";


@CwtSchema()
export class EvalMentalResult extends CwtApiSchema {
    @CwtProp() vpeak: Number;
}

@CwtModel({ name: 'eval-mental' })
export class EvalMental extends EvaluationResultEntity {
    @CwtProp({ ref: EvalMentalResult }) result: EvalMentalResult;
}