import { } from '@angular/animations/browser';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CwtCoreModule } from '@cawita/core-front';
import { CwtZorroModule } from '@cawita/core-front/zorro';
import { QuillConfig } from '@shared/config/quill.config';
import { SharedCommonModule } from '@shared/modules';
import { SharedUserModule } from '@shared/modules/user';
import { QuillModule } from 'ngx-quill';
import { environment } from '../../environments/environment';
import { CwtAdminCoreConfig } from '../configs/core-front.config';
import { AppComponent } from './app.component';
import AppRoutes from './app.routes';
import { RecaptchaModule } from '@shared/widgets/common/recaptcha';
import { NgxEchartsModule } from 'ngx-echarts';
import { EChartTheme } from '@shared/config/chart.config';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CwtCoreModule.forRoot(CwtAdminCoreConfig),
    CwtZorroModule.forRoot(),
    SharedCommonModule.forRoot(),
    SharedUserModule.forRoot(),
    QuillModule.forRoot(QuillConfig),
    RecaptchaModule.forRoot(environment.recaptchaSiteKey),
    RouterModule.forRoot(AppRoutes),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts').then(e => {
        e.registerTheme('walden', EChartTheme);
        return e;
      })
    }),
  ]
})
export class AppModule { }
