'use-strict';

const { isset } = require('@hints/utils/data');
const { isArray } = require('@hints/utils/types');
const data = require('../data/population-stats.json');

class PopulationStat {    
    constructor(stats) {
        this.stats = {
            mr: (isArray(stats) ? stats : stats.mr || []),
            ms: (isArray(stats) ? stats : stats.ms || [])
        };
    }

    /**
     * 
     * @param {'mr' | 'ms'} gender 
     * @param {number} age 
     * @returns {any}
     */
    getData(gender, age) {
        const source = this.stats[gender] || this.stats.mr;
        const entry = source.find(({ age: { min = null, max = null } }) => {
            if(isset(min) && age < min) return false;
            else if(isset(max) && age >= max) return false;
            else return true;
        });
        if(isset(entry)) return entry.data;
        return undefined;
    }
}

module.exports = {
    energeticalRMSSD: new PopulationStat(data.energeticalRMSSD),
    attentionResponseTime: new PopulationStat(data.attentionResponseTime),
    comparisonResponseTime: new PopulationStat(data.comparisonResponseTime),
    trackingTime: new PopulationStat(data.trackingTime),
    mentalAvgSaccAmp: new PopulationStat(data.mentalAvgSaccAmp),
};
