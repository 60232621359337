'use-strict';

const { sum } = require('@hints/utils/math');

/**
 * @param {number[]} rrIntervals vector containing RR intervals in seconds
 * @param {number} grade 
 * @returns {number[]} relative RR intervals
 */
function rrx(rrIntervals, grade = 1) {
    // Convert RR to an array and ensure it is a column vector
    rrIntervals = Array.from(rrIntervals);

    // Initialize rr array with NaN values for the first `grade` elements
    let rr = new Array(grade).fill(NaN);

    // Calculate relative RR intervals of the specified grade
    for (let i = grade; i < rrIntervals.length; i++) {
        if (!isNaN(rrIntervals[i]) && !isNaN(rrIntervals[i - grade])) {
            rr[i] = 2 * (rrIntervals[i] - rrIntervals[i - grade]) / (rrIntervals[i] + rrIntervals[i - grade]);
        } else {
            rr[i] = NaN;
        }
    }

    return rr;
}

/**
 * 
 * @param {number[]} rrIntervals 
 * @param {number} limit 
 * @returns 
 */
function rrFilter(rrIntervals, limit = 20) {
    // Convert RR to an array
    rrIntervals = Array.from(rrIntervals);

    // Replace RR intervals greater than 4 seconds with NaN
    rrIntervals = rrIntervals.map(interval => interval > 4 ? NaN : interval);

    // Calculate relative RR intervals in percent
    let rrPercent = rrx(rrIntervals).map(value => value * 100);

    // Remove unreasonable beat differences
    const maxLimit = Math.max(limit, 50);
    rrIntervals = rrIntervals.map((interval, idx) => rrPercent[idx] > maxLimit && (rrPercent[idx + 1] < -maxLimit || rrPercent[idx + 1] === undefined) ? NaN : interval);
    rrPercent = rrx(rrIntervals).map(value => value * 100);

    // Apply wrong beat position filtering
    for (let wbp_lim = 80; wbp_lim >= limit; wbp_lim -= 10) {
        const wbp = findWrongBeatPositions(rrPercent, wbp_lim);
        wbp.forEach(pos => {
            if (pos + 1 < rrIntervals.length) rrIntervals[pos + 1] = NaN;
            if (pos + 2 < rrIntervals.length) rrIntervals[pos + 2] = NaN;
        });
        rrPercent = rrx(rrIntervals).map(rr => rr * 100);
    }

    // Remove unreasonable differences after NaN-values
    let positions = rrIntervals.slice(0, -2).map((value, idx) => isNaN(value) ? idx : -1).filter(value => value !== -1);
    positions.forEach(idx => {
        if (Math.abs(rrPercent[idx + 2]) > 15) {
            rrIntervals[idx + 1] = NaN;
        }
    });

    rrPercent = rrx(rrIntervals).map(value => value * 100);

    // Remove unreasonable rr_pct values
    positions = rrPercent.map((value, idx) => Math.abs(value) > maxLimit ? idx : -1).filter(value => value !== -1);
    positions.forEach(idx => {
        if (idx > 0) rrIntervals[idx - 1] = NaN;
        rrIntervals[idx] = NaN;
    });

    return rrIntervals;
}

function rmssd(rrIntervals) {
    const dRR = rrIntervals.slice(1).map((val, idx) => Math.pow(val - rrIntervals[idx], 2));
    const validDRR = dRR.filter(v => !isNaN(v));
    return Math.sqrt(sum(validDRR) / validDRR.length);
}




module.exports = {
    rrFilter,
    rmssd,
};

function findWrongBeatPositions(rrPercents, wbpLim) {
    let wbp = [];
    for (let i = 1; i < rrPercents.length; i++) {
        if (Math.abs(rrPercents[i] - rrPercents[i - 1]) > wbpLim) {
            wbp.push(i - 1);
        }
    }

    // Apply filtering to remove positions that are not consecutive
    return wbp.filter((pos, i, arr) => i > 0 && (pos === arr[i - 1] + 1) && (i < arr.length - 1) && (pos === arr[i + 1] - 1));
}
