import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { User } from "../auth";
import { Client } from "./client.model";
import type { Site } from "./site.model";

@CwtModel({ name: 'client-access' })
@CwtCrudContract({ path: 'client-access' })
@CwtCrudContract({ path: 'for-manager/client/:clientId/access', identifier: 'for-manager' })
export class ClientAccess extends CwtApiEntity {
    @CwtProp({ ref: Client }) client: Client;
    @CwtProp({ ref: 'site' }) sites: Site[];
    @CwtProp({ ref: User }) user: User;
    @CwtProp() permissions: string[];
}