import { Injectable } from '@angular/core';
import { CwtNzMessageService } from '@cawita/core-front/zorro';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(
    private i18n: TranslateService,
    private alert: NzMessageService,
    private alert2: CwtNzMessageService
  ) { }

  public wrapDownload(obs: Observable<Blob>) {
    const loading = this.alert.loading(this.i18n.instant('alert.download-started'));
    return obs.pipe(tap({
      next: () => {
        this.alert.remove(loading.messageId);
        this.alert2.success('alert.download-complete');
      },
      error: () => {
        this.alert.remove(loading.messageId);
      }
    }));
  }
}
