import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CwtCommonModule } from '@cawita/core-front';
import { CwtAssetModule } from '@cawita/core-front/asset';
import { CwtAuthModule } from '@cawita/core-front/auth';
import { CwtStateModule } from '@cawita/core-front/state';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { SharedUserModule } from '../user';
import { DownloadUploadFileDirective } from './directives';
import { CloseDrawerDirective } from './directives';
import { CloseModalDirective } from './directives';
import { ConfirmDirective } from './directives';
import { CropTextDirective } from './directives';
import { GoBackDirective } from './directives';
import { HighlightDiffDirective } from './directives';
import { IfBreakpointDirective } from './directives';
import { LetResolvedDirective } from './directives';
import { PickFileDirective } from './directives';
import { RatioPipe, TextColorPipe } from './pipes';
import { NamePipe } from './pipes';
import { RoundPipe } from './pipes';
import { SortPipe } from './pipes';
import { LocationService, initLocation } from './services';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzResultModule } from 'ng-zorro-antd/result';
import { CountUpDirective } from './directives/count-up.directive';
import { ReversePipe } from './pipes/reverse.pipe';

@NgModule({
    imports: [
        PickFileDirective,
        TextColorPipe,
        RatioPipe,
        NamePipe,
        SortPipe,
        LetResolvedDirective,
        CloseModalDirective,
        CloseDrawerDirective,
        IfBreakpointDirective,
        GoBackDirective,
        ConfirmDirective,
        HighlightDiffDirective,
        CropTextDirective,
        DownloadUploadFileDirective,
        RoundPipe,
        CountUpDirective,
    ],
    exports: [
        CommonModule,
        CwtStateModule,
        CwtCommonModule,
        CwtAuthModule,
        CwtAssetModule,
        RouterModule,
        NzTypographyModule,
        NzEmptyModule,
        NzResultModule,
        NzSpaceModule,
        NzCardModule,
        NzGridModule,
        NzButtonModule,
        NzIconModule,
        NzModalModule,
        NzSpinModule,
        NzTagModule,
        NzPopconfirmModule,
        SharedUserModule,
        NzToolTipModule,
        NzDividerModule,
        PickFileDirective,
        TextColorPipe,
        RatioPipe,
        NamePipe,
        SortPipe,
        LetResolvedDirective,
        CloseModalDirective,
        CloseDrawerDirective,
        IfBreakpointDirective,
        GoBackDirective,
        ConfirmDirective,
        HighlightDiffDirective,
        CropTextDirective,
        DownloadUploadFileDirective,
        RoundPipe,
        CountUpDirective,
        ReversePipe,
    ],
    providers: [],
    declarations: [
      ReversePipe
    ],
})
export class SharedCommonModule {
    static forRoot(): ModuleWithProviders<SharedCommonModule> {
        return {
            ngModule: SharedCommonModule,
            providers: [
                { provide: APP_INITIALIZER, deps: [LocationService], useFactory: initLocation(), multi: true },
            ]
        }
    }
}
