import { CwtApiSchema, CwtProp, CwtSchema } from "@cawita/core-front/api";

export enum RecoLevel {
    React = 'react',
    Act = 'act',
    Monitor = 'monitor',
    Optimize = 'optimize',
    Maintain = 'maintain',
}

export enum RecoType {
    Sleep = 'sleep',
    Emotional = 'emotional',
    Physical = 'physical',
    Mental = 'mental',
    Energetical = 'energetical',
    Cerebral = 'cerebral',
    Pain = 'pain', // Physical with physical.painScore > physical.score 
}

export class EvaluationRecommendationType extends CwtApiSchema {
    @CwtProp() score: number;
    @CwtProp() type: RecoType;
    @CwtProp() level: RecoLevel;
}

@CwtSchema()
export class EvaluationRecommendation extends CwtApiSchema {
    @CwtProp() averageScore: number;
    @CwtProp() averageLevel: RecoLevel;
    @CwtProp() categoryLevel: RecoLevel;
    @CwtProp() categoryType: RecoType;
    @CwtProp({ ref: EvaluationRecommendationType }) perType: EvaluationRecommendationType[];
}