const { isNumber, isString, isDate } = require('@hints/utils/types');
const { TemplateConfiguration } = require('./template-utils');
const currencyService = require('./formatting/currency-service');
const { format } = require('date-fns');

const config = new TemplateConfiguration({
    forgottenPassword: {
        type: 'mail',
        for: 'system',
        variables: {
            url: { type: 'url', label: 'template-variables.url', required: true },
        },
    },
    newPasswordEmail: {
        type: 'mail',
        for: 'system',
        variables: {
            url: { type: 'url', label: 'template-variables.url', required: true },
        },
    },
    askNewPasswordEmail: {
        type: 'mail',
        for: 'system',
        variables: {
            url: { type: 'url', label: 'template-variables.url', required: true },
        },
    },
    invitationMail: {
        type: 'mail',
        for: 'system',
        variables: {
            userName: { type: 'string', label: 'template-variables.user-name' },
            clientName: { type: 'string', label: 'template-variables.client-name' },
            url: { type: 'url', label: 'template-variables.url', required: true },
        }
    },
    invitationRefused: {
        type: 'mail',
        for: 'system',
        variables: {
            userHostName: { type: 'string', label: 'template-variables.user-host-name' },
            userName: { type: 'string', label: 'template-variables.user-name' },
            clientName: { type: 'string', label: 'template-variables.customer-name' },
        }
    },
    invitationAccepted: {
        type: 'mail',
        for: 'system',
        variables: {
            userHostName: { type: 'string', label: 'template-variables.user-host-name' },
            userName: { type: 'string', label: 'template-variables.user-name' },
            clientName: { type: 'string', label: 'template-variables.customer-name' },
        }
    },
    confirmationRegisterMail: {
        type: 'mail',
        for: 'system',
        variables: {
            url: { type: 'url', label: 'template-variables.url', required: true },
        },
    },
    generic: {
        type: 'mail',
        for: 'admin',
        variables: {}
    },
    forgottenPasswordTester: {
        type: 'mail',
        for: 'system',
        variables: {
            code: { type: 'string', label: 'template-variables.code' },
        }
    },
    evaluationResult: {
        type: 'mail',
        for: 'system',
        variables: {
            login: { type: 'string', label: 'template-variables.login' },
            date: { type: 'date', label: 'template-variables.date' },
        }
    }
}, {
    currency: { validate: isNumber, format: v => currencyService.format(v) },
    url: { validate: isString, format: v => `<a href="${v}">${v}</a>` },
    date: { validate: (v) => isDate(v, false), format: v => format(new Date(v), 'dd/MM/yyyy') }
});

module.exports = {
    config
};