import { CwtApiEntity, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { Client, Machine, Site } from "../client";
import { UploadFile } from "../common";
import { Evaluation } from "./evaluation.model";

@CwtSchema()
export class EvaluationResultEntity extends CwtApiEntity {
    @CwtProp() client: Client;
    @CwtProp() site: Site;
    @CwtProp() machine: Machine;
    @CwtProp({ ref: Date }) date: Date;
    @CwtProp({ ref: Evaluation }) evaluation: Evaluation;
    @CwtProp() score: number;
    @CwtProp() logs: UploadFile;
}