import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Client } from "./client.model";
import { Site } from "./site.model";

@CwtModel({ name: 'machine' })
@CwtCrudContract({ 
    path: 'machine',
    searchMapper: (s) => ({ name: { regexp: s } }),
 })
@CwtCrudContract({ 
    path: 'for-manager/client/:clientId/machine', identifier: 'for-manager',
    searchMapper: (s) => ({ name: { regexp: s } }),
})
export class Machine extends CwtApiEntity {
    @CwtProp() name: string;
    @CwtProp({ ref: Client }) client: Client;
    @CwtProp({ ref: Site }) site: Site;
    @CwtProp() uniqueId: string;
    @CwtProp() password: string;
    @CwtProp() macAddr: string;
}