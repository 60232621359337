import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { User } from "../auth";
import { Client } from "./client.model";
import type { Site } from "./site.model";

export enum InvitationStatus {
    Pending = 'pending',
    Accepted = 'accepted',
    Refused = 'refused',
}

@CwtModel({ name: 'invitation' })
@CwtCrudContract({ 
    path: 'invitation',
    searchMapper: (s) => ({ email: { regexp: s } }),
 })
@CwtCrudContract({ 
    path: 'for-manager/client/:clientId/invitation', identifier: 'for-manager',
    searchMapper: (s) => ({ email: { regexp: s } }),
 })
export class Invitation extends CwtApiEntity {
    @CwtProp({ ref: User }) user: User;
    @CwtProp({ ref: Client }) client: Client;
    @CwtProp() permissions: string[];
    @CwtProp() email: string;
    @CwtProp({ ref: User }) host: User;
    @CwtProp({ ref: User }) invitee: User;
    @CwtProp({ ref: Date }) expirationDate: Date;
    @CwtProp() status: InvitationStatus;
    @CwtProp({ ref: 'site' }) sites: Site[];
}