import { CwtApiSchema, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { EvaluationResultEntity } from "./eval-result.entity";

@CwtSchema()
export class EvalCerebralResult extends CwtApiSchema {
    @CwtProp() attentionAccuracy: number;
    @CwtProp() attentionResponseTime: number;
    @CwtProp() comparisonAccuracy: number;
    @CwtProp() comparisonResponseTime: number;
    @CwtProp() trackingTime: number;
}

@CwtModel({ name: 'eval-cerebral' })
export class EvalCerebral extends EvaluationResultEntity {
    @CwtProp() attentionScore: number;
    @CwtProp() comparisonScore: number;
    @CwtProp() trackingScore: number;
    @CwtProp({ ref: EvalCerebralResult }) result: EvalCerebralResult;
}