// @index(['./!(*.spec).ts', './*/index.ts'], f => `export * from '${f.path.replace(/\/index$/, '')}';`)
export * from './eval-cerebral.model';
export * from './eval-emotional.model';
export * from './eval-energetical.model';
export * from './eval-mental.model';
export * from './eval-physical.model';
export * from './eval-result.entity';
export * from './eval-sleep.model';
export * from './evaluation.model';
export * from './question-eval.model';
export * from './tester.model';
// @endindex