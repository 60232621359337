import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp, CwtSchema } from "@cawita/core-front/api";
import { Client, Machine, Site } from "../client";
import { Tester } from "./tester.model";
import type { EvalCerebral } from "./eval-cerebral.model";
import type { EvalEmotional } from "./eval-emotional.model";
import type { EvalEnergetical } from "./eval-energetical.model";
import type { EvalMental } from "./eval-mental.model";
import type { EvalPhysical } from "./eval-physical.model";
import type { EvaluationResultEntity } from "./eval-result.entity";
import type { EvalSleep } from "./eval-sleep.model";
import { InjectionToken } from "@angular/core";
import { EvaluationRecommendation } from "./recommendation.model";

export enum EvaluationStatus {
    Pending = 'pending',
    Done = 'done'
}

@CwtModel({ name: 'evaluation' })
@CwtCrudContract({ path: 'evaluation' })
@CwtCrudContract({ path: 'for-manager/client/:clientId/evaluation', identifier: 'for-manager' })
@CwtCrudContract({ path: 'for-machine/evaluation', identifier: 'for-machine' })
export class Evaluation extends CwtApiEntity {
    @CwtProp({ ref: Tester }) tester: Tester;
    @CwtProp() client: Client;
    @CwtProp({ ref: Site }) site: Site;
    @CwtProp({ ref: Machine }) machine: Machine;
    @CwtProp({ ref: Date }) date: Date;
    @CwtProp() status: EvaluationStatus;
    @CwtProp({ ref: 'eval-sleep' }) evalSleep: EvalSleep;
    @CwtProp({ ref: 'eval-physical' }) evalPhysical: EvalPhysical;
    @CwtProp({ ref: 'eval-emotional' }) evalEmotional: EvalEmotional;
    @CwtProp({ ref: 'eval-mental' }) evalMental: EvalMental;
    @CwtProp({ ref: 'eval-energetical' }) evalEnergetical: EvalEnergetical;
    @CwtProp({ ref: 'eval-cerebral' }) evalCerebral: EvalCerebral;
    @CwtProp({ ref: EvaluationRecommendation }) recommendation: EvaluationRecommendation;
}

export type EvaluationMode = 'training' | 'real';
export type EvaluationType = 'sleep' | 'physical' | 'emotional' | 'energetical' | 'mental' | 'cerebral';
export type EvaluationField = { [K in keyof Evaluation]-?: Evaluation[K] extends EvaluationResultEntity ? K : never; }[keyof Evaluation];

export const EvaluationNames: Record<EvaluationType, EvaluationField> = {
    'sleep': 'evalSleep',
    'physical': 'evalPhysical',
    'emotional': 'evalEmotional',
    'energetical': 'evalEnergetical',
    'mental': 'evalMental',
    'cerebral': 'evalCerebral',
};

export const EvaluationTypeOrderInDashboard: EvaluationType[] = ['sleep', 'physical', 'emotional', 'energetical', 'mental', 'cerebral'];
export const EvaluationTypeOrderInEvaluation: EvaluationType[] = ['sleep', 'physical', 'emotional', 'energetical', 'mental', 'cerebral'];
export const EvaluationTypeOrderInRadarChart: EvaluationType[] = ['physical', 'emotional', 'sleep', 'mental', 'cerebral', 'energetical'];
export const EvaluationTypeOrderInStatistics: EvaluationType[] = ['sleep', 'physical', 'emotional', 'energetical', 'mental', 'cerebral'];