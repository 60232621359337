'use-strict';

const { asArray, isset } = require('@hints/utils/data');
const Protocol = require('./protocol');
const hrv = require('../utils/hrv');
const { normalize0100, average } = require('../utils/math');
const pop = require('./population-stats');

class EnergeticalProtocol extends Protocol {
    getScore(gender, age, ppi) {
        const { mean, sd } = pop.energeticalRMSSD.getData(gender, age);
        const heartrate = this.computeMeanHR(ppi);
        const rrIntervals = asArray(ppi).map(ppi => ppi.ppi / 1000);
        const rrIntervalsFiltered = hrv.rrFilter(rrIntervals);
        const rmssd = (hrv.rmssd(rrIntervalsFiltered) || 0.001) * 1000;
        const lnRMSSD = Math.log(rmssd);
        const score = normalize0100((lnRMSSD - mean) / sd, -3, 3);
        return {
            logs: ppi,
            data: {
                score: score,
                result: {
                    rmssd,
                    heartrate
                }
            }
        };
    }

    validateResults(body) {
        if (!super.validateResults(body)) return false;
        const { result } = body;
        if (!isset(result)) return false;
        if (!isset(result.rmssd)) return false;
        if (!isset(result.heartrate)) return false;
        return true;
    }

    computeMeanHR(ppi) {
        const validHR = ppi.map(ppi => ppi.ppiHr).filter(hr => hr > 0);
        return average(validHR);
    }
}

module.exports = new EnergeticalProtocol();