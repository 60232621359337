import permissions from './permissions.json';
import clientPermissions from './permissions/client-permissions.json';
import emailTemplate from './email-template-service-shared';
import sleepProtocol from './evaluation/protocols/sleep-protocol.js';
import emotionalProtocol from './evaluation/protocols/emotional-protocol.js';
import physicalProtocol from './evaluation/protocols/physical-protocol.js';
import cerebralProtocol from './evaluation/protocols/cerebral-protocol.js';
import mentalProtocol from './evaluation/protocols/mental-protocol.js';
import energeticalProtocol from './evaluation/protocols/energetical-protocol.js';
import recommendation from './evaluation/eval-recommendations.js';

export const AvailablePermissions = permissions;
export const EmailTemplateService = emailTemplate.config;

export const ClientPermissions = clientPermissions;
export type ClientPermission = typeof clientPermissions[0];

export type _AssignablePermissionsPerAccessType = Record<string, string[]>;
export type _AssignablePermissionsPerAccessTypeAndCategory = Record<string, Record<string, string[]>>;

export const AssignablePermissionsPerAccessType: _AssignablePermissionsPerAccessType = permissions
    .filter(perm => !perm.nonAssignable)
    .reduce((map, perm) => {
        perm.accessTypes.forEach(accessType => {
            if (!map[accessType]) map[accessType] = [perm.name];
            else map[accessType].push(perm.name);
        });
        return map;
    }, {});

export const AssignablePermissionsPerAccessTypeAndCategory: _AssignablePermissionsPerAccessType = permissions
    .filter(perm => !perm.nonAssignable)
    .reduce((map, perm) => {
        perm.accessTypes.forEach(accessType => {
            if (!map[accessType]) map[accessType] = {};
            if (!map[accessType][perm.category]) map[accessType][perm.category] = [perm.name];
            else map[accessType][perm.category].push(perm.name);
        });

        return map;
    }, {});


export const SleepProtocol: typeof sleepProtocol = sleepProtocol;
export const EmotionalProtocol: typeof sleepProtocol = emotionalProtocol;
export const PhysicalProtocol: typeof physicalProtocol = physicalProtocol;
export const CerebralProtocol: typeof cerebralProtocol = cerebralProtocol;
export const MentalProtocol: typeof mentalProtocol = mentalProtocol;
export const EnergeticalProtocol: typeof energeticalProtocol = energeticalProtocol;
export const Recommendation: typeof recommendation = recommendation;