'use-strict';

const { median } = require('./math');

const REALMIN = Number.MIN_VALUE;

/**
 * 
 * @param {[number, number][]} velocities 
 * @returns { msdx: number, msdy: number }
 */
function velthresh(velocities) {
    const horVel = velocities.map(row => row[0]);
    const vertVel = velocities.map(row => row[1]);
    let msdx = Math.sqrt(median(horVel.map(x => x * x)) - Math.pow(median(horVel), 2));
    let msdy = Math.sqrt(median(vertVel.map(y => y * y)) - Math.pow(median(vertVel), 2));
    if (msdx < REALMIN) {
        msdx = Math.sqrt(horVel.reduce((acc, x) => acc + x * x, 0) / horVel.length - Math.pow(horVel.reduce((acc, x) => acc + x, 0) / horVel.length, 2));
        if (msdx < REALMIN) msdx = Infinity;
    }

    if (msdy < REALMIN) {
        msdy = Math.sqrt(vertVel.reduce((acc, y) => acc + y * y, 0) / vertVel.length - Math.pow(vertVel.reduce((acc, y) => acc + y, 0) / vertVel.length, 2));
        if (msdy < REALMIN) msdy = Infinity;
    }
    return { msdx, msdy };
}

/**
 * Computes velocities using 5 points smoothing or something i don't know...
 * @param {[number, number][]} positions
 * @param {number} sampling 
 * @returns {[number, number][]} velocties
 */
function vecvel(positions, sampling) {
    const length = positions.length;
    /**
     * @type {[number, number][]}
     */
    const results = new Array(length).fill(0).map(() => ([0, 0]));
    const halfSampling = sampling / 2;
    const sixthSampling = sampling / 6;
    const pt = (i, c) => positions[i][c];

    const sample5 = (i, c) => sixthSampling * (pt(i + 2, c) + pt(i + 1, c) - pt(i - 1, c) - pt(i - 2, c));
    const sample2 = (i, c) => halfSampling * (pt(i, c) - pt(i - 2, c));

    for (let i = 2; i < length - 2; i++) results[i] = [sample5(i, 0), sample5(i, 1)];
    if (length > 1) {
        results[1] = [sample2(2, 0), sample2(2, 1)];
        results[length - 2] = [sample2(length - 1, 0), sample2(length - 1, 1)];
    }
    return results;
}

/**
 * @typedef {Object} Saccade
 * @property {number} onset
 * @property {number} end
 * @property {number} vpeak
 * @property {number} dx
 * @property {number} dy
 * @property {number} ax
 * @property {number} ay
 * @property {number} ah
 */

/**
 * 
 * @param {[number, number][]} positions 
 * @param {[number, number][]} velocities 
 * @param {number} relVelocityThreshold 
 * @param {number} minSaccadeDuration 
 * @returns {{ saccades: Saccade[], radius: [number, number] }}
 */
function microsacc(positions, velocities, relVelocityThreshold, minSaccadeDuration) {
    const { msdx, msdy } = velthresh(velocities);
    const radiusx = relVelocityThreshold * msdx;
    const radiusy = relVelocityThreshold * msdy;
    const radius = [radiusx, radiusy];
    const test = velocities.map(v => Math.pow(v[0] / radiusx, 2) + Math.pow(v[1] / radiusy, 2));
    const indx = test.reduce((acc, t, i) => (t > 1 ? acc.concat(i) : acc), []);

    const N = indx.length;
    /**
     * @type {Saccade[]}
     */
    const saccades = [];
    let duration = 1;
    let a = 0; // unknown
    let k = 0; // unknown

    const addSaccade = (onset = 0, end = 0) => saccades.push({ onset, end, vpeak: 0, dx: 0, dy: 0, ax: 0, ay: 0, ah: 0 });
    const setVPeak = (index, vpeak) => saccades[index].vpeak = vpeak;
    const setComponents = (index, dx, dy) => { saccades[index].dx = dx; saccades[index].dy = dy; };
    const setAmplitudes = (index, ax, ay) => {
        saccades[index].ax = ax;
        saccades[index].ay = ay;
        saccades[index].ah = Math.hypot(ax, ay);
    };

    while (k < N - 1) {
        if (indx[k + 1] - indx[k] === 1) {
            duration += 1;
        } else {
            if (duration >= minSaccadeDuration) addSaccade(indx[a], indx[k]);
            a = k + 1;
            duration = 1;
        }
        k += 1;
    }

    if (duration >= minSaccadeDuration) addSaccade(indx[a], indx[k]);

    for (let s = 0; s < saccades.length; s++) {
        const onset = saccades[s].onset;
        const offset = saccades[s].end;
        const vpeak = Math.max(...velocities.slice(onset, offset + 1).map(v => Math.sqrt(v[0] * v[0] + v[1] * v[1])));
        setVPeak(s, vpeak);

        const dx = positions[offset][0] - positions[onset][0];
        const dy = positions[offset][1] - positions[onset][1];
        setComponents(s, dx, dy);

        const segment = positions.slice(onset, offset + 1);
        const minx = Math.min(...segment.map(p => p[0]));
        const maxx = Math.max(...segment.map(p => p[0]));
        const miny = Math.min(...segment.map(p => p[1]));
        const maxy = Math.max(...segment.map(p => p[1]));
        const dX = Math.sign(segment.findIndex(p => p[0] === maxx) - segment.findIndex(p => p[0] === minx)) * (maxx - minx);
        const dY = Math.sign(segment.findIndex(p => p[1] === maxy) - segment.findIndex(p => p[1] === miny)) * (maxy - miny);
        setAmplitudes(s, dX, dY);
    }

    return { saccades, radius };
}

module.exports = { velthresh, vecvel, microsacc };