'use-strict';

const { isset } = require('@hints/utils/data');
const { sum, ratio, clamp } = require('@hints/utils/math');

/**
 * @param {number[]} arr 
 * @returns {number} median
 */
function median(arr) {
    const sorted = arr.slice().sort((a, b) => a - b);
    const mid = Math.floor(sorted.length / 2);
    return sorted.length % 2 !== 0 ? sorted[mid] : (sorted[mid - 1] + sorted[mid]) / 2;
}
/**
 * 
 * @param {number[]} values 
 */
function average(values) { return ratio(sum(values), values.length); }

/**
 * 
 * @param {number[]} values 
 * @param {number[]} weights 
 */
function weightedAverage(values, weights) {
    let totalValue = 0;
    let totalWeight = 0;
    for(let i = 0; i < values.length; i++) {
        const weight = isset(weights[i]) ? weights[i] : 1;
        totalValue += (values[i] || 0) * weight; 
        totalWeight += weight;
    }
    return ratio(totalValue, totalWeight);
}

function degToRad(deg) { return deg * (Math.PI / 180); }
function radToDeg(rad) { return (180 / Math.PI) * rad; }
function atan2Deg(x, y) { return radToDeg(Math.atan2(x, y)); }

function clamp0100(value) { return clamp(Math.round(value), 0, 100); }

function normalize(value, min, max) {
    if(!isset(value)) value = 0;
    const normalized = ((value - min) / (max - min));
    const clamped = clamp(normalized, 0, 1);
    return clamped;
}

function normaleInverse(value, min, max) {
    if(!isset(value)) value = 0;
    const normalized = ((value - max) / (min - max));
    const clamped = clamp(normalized, 0, 1);
    return clamped;
}

function normalize0100(value, min, max) {
    const normalized = normalize(value, min, max);
    return clamp(Math.round(normalized * 100), 0, 100);
}

function normalizeInverse0100(value, min, max) {
    const normalized = normaleInverse(value, min, max);
    return clamp(Math.round(normalized * 100), 0, 100);
}

 
module.exports = {
    average,
    weightedAverage,
    median,
    degToRad,
    radToDeg,
    atan2Deg,
    clamp0100,
    normalize,
    normalize0100,
    normaleInverse,
    normalizeInverse0100
};