import { CwtDictionary } from "@cawita/core-front";
import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";

export type TemplateType = 'mail';
export type TemplateFor = 'app' | 'system';
export type TemplateAttachments = 'mandatory' | 'accepted' | 'none';

export enum TemplateUseCase {
    ForgottenPassword = 'forgottenPassword',
    ForgottenPasswordTester = 'forgottenPasswordTester',
    NewPasswordEmail = 'newPasswordEmail',
    ConfirmationRegisterMail = 'confirmationRegisterMail',
    Generic = 'generic',
}


@CwtModel({ name: 'template' })
@CwtCrudContract({ path: 'template' })
export class Template extends CwtApiEntity {
    @CwtProp() name: string;
    @CwtProp() type: TemplateType;
    @CwtProp() for: TemplateFor;
    @CwtProp() useCase: TemplateUseCase;
    @CwtProp() subject: CwtDictionary;
    @CwtProp() content: CwtDictionary;
    @CwtProp() isActive: boolean;
}