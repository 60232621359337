import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Client } from "../client";
import { isset } from "@hints/utils/data";
import { differenceInIntYears, getIntYearAsDate } from "@hints/utils/date";
import { differenceInYears, getYear } from "date-fns";

export enum TesterGender {
    MR = 'mr',
    MS = 'ms',
    OTHER = 'other',
    NO = 'no',
}

export enum TesterMorphology {
    SKINNY = 'skinny',
    MEDIUM = 'medium',
    FAT = 'fat',
}

export function TesterSearchMapper(search: string) {
    return ({
        or: [
            { login: { regexp: search } },
            { email: { regexp: search } }
        ]
    })
}

@CwtModel({ name: 'tester' })
@CwtCrudContract({ path: 'tester', searchMapper: TesterSearchMapper })
@CwtCrudContract({ path: 'for-machine/tester' , identifier:'for-machine' })
@CwtCrudContract({ path: 'for-manager/client/:clientId/tester', identifier: 'for-manager' })
export class Tester extends CwtApiEntity {
    @CwtProp({ ref: Client }) client: Client;
    @CwtProp() login: string;
    @CwtProp() gender: TesterGender;
    @CwtProp() email: string;
    @CwtProp() password: string;
    @CwtProp() birthYear: number;
    @CwtProp() height: number; // cm
    @CwtProp() weight: number; // kg
    @CwtProp() morphology: TesterMorphology; 

    public get age() {
        if(!isset(this.birthYear)) return null;
        return differenceInIntYears(getYear(new Date()), this.birthYear);
    }

    public get coercedGender() {
        if (this.gender === TesterGender.MS) return TesterGender.MS;
        return TesterGender.MR;
    }
}