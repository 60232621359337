'use-strict';

const { sum, clamp } = require('@hints/utils/math');
const Protocol = require('./protocol');
const config = require('../data/physical-config.json');
const { clamp0100, weightedAverage, normalize0100 } = require('../utils/math');
const { isset } = require('@hints/utils/data');

/**
 * @typedef {Object} PainPoint
 * @property {number} x
 * @property {number} y
 * @property {'low' | 'medium' | 'high'} level
 * @property {'small' | 'medium' | 'large'} size
 */

/**
 * @typedef {Object} PhysicalScoreParams
 * AP PROPERTIES
 * @property {boolean} isPhysicallyActive
 * @property {string} activityDuration
 * 
 * PS PROPERTIES
 * @property {boolean} isPracticingSports
 * @property {string[]} practicedSports
 * @property {string} practiceFrequency
 * @property {string} practiceDuration
 * 
 * EVA PROPERTIES
 * @property {number} wellness
 * 
 * PAIN PROPERTIES
 * @property {PainPoint[]} backPainPoints
 * @property {PainPoint[]} frontPainPoints
 */


const PHYSICALLY_ACTIVE_FIELDS = ['activityDuration'];
const PRACTICING_SPORT_FIELDS = ['practicedSports', 'practiceFrequency', 'practiceDuration'];

class PhysicalProtocol extends Protocol {
    constructor() {
        super();
        this.sports = Object.keys(config.sportValues);
        this.activityDurations = Object.keys(config.activityDurationValues);
        this.practiceDurations = Object.keys(config.practiceDurationValues);
        this.practiceFrequencies = Object.keys(config.practiceFrequencyValues);
        this.painLevels = Object.keys(config.painLevelValues);
        this.painSizes = Object.keys(config.painSizeValues);
    }

    /**
     * @param {'ms' | 'mr'} gender 
     * @param {number} age 
     * @param {PhysicalScoreParams} data 
     */
    getScore(gender, age, data) {
        const wellnessScore = this.getWellnessScore(data);
        const activityScore = this.getActivityScore(data);
        const painScore = this.getPainScore(data);
        const score = weightedAverage([wellnessScore, activityScore, 100 - painScore], [config.wellnessCoeff, config.activityCoeff, config.painCoeff]);
        const { isPhysicallyActive = false, isPracticingSports = false } = data;
        const optionalResults = { };
        PHYSICALLY_ACTIVE_FIELDS.forEach(p => optionalResults[p] = isPhysicallyActive ? data[p] : null);
        PRACTICING_SPORT_FIELDS.forEach(p => optionalResults[p] = isPracticingSports ? data[p] : null);
        return {
            data: {
                score: clamp0100(score),
                painScore: clamp0100(painScore),
                activityScore: clamp0100(activityScore),
                wellnessScore: clamp0100(wellnessScore),
                result: {
                    wellness: data.wellness,
                    backPainPoints: data.backPainPoints,
                    frontPainPoints: data.frontPainPoints,
                    isPhysicallyActive: data.isPhysicallyActive,
                    isPracticingSports: data.isPracticingSports,
                    ...optionalResults
                }
            },
        };
    }
    
    validateResults(body) {
        if(!super.validateResults(body)) return false;
        const { painScore, activityScore, wellnessScore, result } = body;
        if([painScore, activityScore, wellnessScore, result].some(v => !isset(v))) return false;
        const { wellness, backPainPoints, frontPainPoints, isPhysicallyActive, isPracticingSports} = result;
        if([wellness, backPainPoints, frontPainPoints, isPhysicallyActive, isPracticingSports].some(v => !isset(v))) return false;
        if (isPhysicallyActive && PHYSICALLY_ACTIVE_FIELDS.some(f => !isset(result[f]))) return false;
        if (isPracticingSports && PRACTICING_SPORT_FIELDS.some(f => !isset(result[f]))) return false;
        return true;
    }
    
    /**
     * @param {PhysicalScoreParams} data 
     */
    getActivityScore(data) {
        const physicalActivity = this.getPhysicalActivity(data);
        const sportPractice = this.getSportPractice(data);
        return weightedAverage(
            [physicalActivity, sportPractice],
            [config.physicalActivityCoeff, config.sportPracticeCoeff]
        );
    }

    /**
     * @param {PhysicalScoreParams} data 
     */
    getPhysicalActivity(data) {
        if (!data.isPhysicallyActive) return 0;
        return normalize0100(config.activityDurationValues[data.activityDuration] || 0, 0, 3);
    }

    /**
     * @param {PhysicalScoreParams} data 
     */
    getSportPractice(data) {
        if(!data.isPracticingSports) return 0;
        const practicedSport = Math.max(...(data.practicedSports || []).map(s => config.sportValues[s] || 0));
        const practiceFrequency = config.practiceFrequencyValues[data.practiceFrequency] || 0; 
        const practiceDuration = config.practiceDurationValues[data.practiceDuration] || 0; 
        return clamp0100(((practicedSport * practiceFrequency * practiceDuration) / 10) * 100);
    }
    
    /**
     * @param {PhysicalScoreParams} data 
     */
    getPainScore(data) {
        const totalFront = sum(data.frontPainPoints.map(p => this.getPainPointValue(p)));
        const totalBack = sum(data.backPainPoints.map(p => this.getPainPointValue(p)));
        return clamp0100(totalFront + totalBack);
    }

    /**
     * @param {PhysicalScoreParams} data 
     */
    getWellnessScore(data) {
        const score = clamp(data.wellness || 0, 0, 10) * 100;
        return clamp0100(score);
    }

    /**
     * 
     * @param {PainPoint} point 
     */
    getPainPointValue(point) {
        const { level = 'low', size = 'small' } = point;
        return (config.painLevelValues[level] * config.painSizeValues[size] * 3);
    }
}

module.exports = new PhysicalProtocol();