import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from '@hints/utils/data';

@Pipe({
  name: 'reverse'
})
export class ReversePipe implements PipeTransform {

  transform<T>(value: T[]): T[] {
    if(isEmpty(value)) return [];
    return [...value].reverse();
  }

}
