'use-strict';

const { asArray, isset, isEmpty } = require('@hints/utils/data');
const Protocol = require('./protocol');
const { sum } = require('@hints/utils/math');
const { clamp0100 } = require('../utils/math');

/**
 * @typedef {Object} ProtocolAnswer
 * @property {string} id
 * @property {{ mr: string, mr: string }} text
 * @property {number} value
 */

/**
 * @typedef {Object} ProtocolQuestion
 * @property {number} order
 * @property {{ mr: string, mr: string }} text
 * @property {ProtocolAnswer[]} answers
 */

/**
 * @typedef {Object} ProtocolResultItem
 * @property {number} questionOrder
 * @property {string} answerId
 * @property {number} value
 */

module.exports = class QuestionProtocol extends Protocol {
    /**
     * @param {(gender: 'mr' | 'ms', age: number, answerScore: number) => number} scoreCalculator
     * @param {ProtocolQuestion[]} questions
     */
    constructor(scoreCalculator, questions) {
        super();
        this.questions = asArray(questions) || [];
        this.range = this.getScoreRange();
        this.questionOrders = this.getQuestionOrders();
        this.answerIds = this.getAnswerIdsPerQuestion();
        this.answerValues = this.getAnswerValuesPerQuestion();
        this.scoreCalculator = scoreCalculator;
    }

    /**
     * 
     * @param {'ms' | 'mr'} gender 
     * @param {number} age 
     * @param {ProtocolResultItem[]} result 
     * @returns {{ score: number, data: { answerScore: number, result: ProtocolResultItem[] }}}
     */
    getScore(gender, age, result) {
        const answerScore = sum(asArray(result).map(({ questionOrder, answerId }) => this.getAnswerScore(questionOrder, answerId)));
        const score = clamp0100(this.scoreCalculator(gender, age, answerScore));
        return { data: { score, answerScore, result } };
    }

    validateResults(body) {
        if(!super.validateResults(body)) return false;
        const { max, min } = this.range;
        const { answerScore, result } = body;
        if(!isset(answerScore)) return false;
        if(answerScore > max) return false;
        if(answerScore < min) return false;
        if(!isset(result) || isEmpty(result) || result.length !== this.questions.length) return false;
        return result.every(({ questionOrder, answerId, value }) => (
            isset(questionOrder) && this.questionOrders.includes(questionOrder) &&
            isset(answerId) && this.answerIds[questionOrder].includes(answerId) &&
            isset(value) && this.answerValues[questionOrder].includes(value)
        ));
    }

    getAnswerScore(questionOrder, answerId) {
        const question = this.questions.find(q => q.order === questionOrder);
        if(isEmpty(question) || isEmpty(question.answers)) return 0;
        const answer = question.answers.find(a => a.id === answerId);
        if (isEmpty(answer) || isEmpty(answer.value)) return 0;
        return answer.value || 0;
    }

    /**
     * 
     * @returns {{ min: number, max: number }}
     */
    getScoreRange() {
        const range = { min: 0, max: 0 };
        for (const question of this.questions) {
            const values = question.answers.map(answer => answer.value);
            range.max += Math.max(...values);
            range.min += Math.min(...values);
        }
        return range;
    }

    /**
     * @returns {number[]}
     */
    getQuestionOrders() {
        return this.questions.map(q => q.order);
    }

    /**
     * @returns {Record<string, number[]>}
     */
    getAnswerValuesPerQuestion() {
        const valuesObj = {};
        for (const question of this.questions) {
            const values = question.answers.map(answer => answer.value);
            valuesObj[`${question.order}`] = values;
        }
        return valuesObj;
    }

    /**
     * @returns {Record<string, string[]>}
     */
    getAnswerIdsPerQuestion() {
        const answerObj = {};
        for (const question of this.questions) {
            const answers = question.answers.map(answer => answer.id);
            answerObj[`${question.order}`] = answers;
        }
        return answerObj;
    }
};